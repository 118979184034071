<template>
  <div class="admin-menu-items">
    <div class="admin-menu-items-content">
      <div :class="`admin-menu-item __home ${$isPath('/') && isHome ? 'on' : ''}`" @click="goFilter({}, '/')">
        <iconic name="house" />
        <span>{{ $locale["words"]["home"] }}</span>
      </div>
      <div :class="`admin-menu-item ${$isPath($links['my-profile']) ? 'on' : ''}`" @click="goFilter({}, $links['my-profile'])">
        <iconic name="user" />
        <span>{{ $locale["words"]["my_profile"] }}</span>
      </div>
      <div :class="`admin-menu-item ${mkLink('messages=wall', 'on')}`" @click="setQuery({ messages: 'wall' })">
        <iconic name="comment_dots" />
        <span>{{ $locale["words"]["messages"] }}</span>
      </div>
      <div :class="`admin-menu-item ${mkLink($links['my-notifications'], 'on')}`" @click="goFilter({ view: 'others' }, $links['my-notifications'])">
        <iconic name="bell" />
        <span>{{ $locale["words"]["notifications"] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isType: function() {
      if (this.$route.query.adminStatus && this.$route.query.type !== "story") {
        return "posts";
      } else if (this.$route.query.adminStatus && this.$route.query.type === "story") {
        return "stories";
      }
    },
    isHome: function() {
      return this.$isPath("/") && !this.$route.query.view;
    },
  },
};
</script>
